<template>
  <div>
    <s-text :readonly="readonly" autofocus :label="label" @click="dialogOrder = true" v-model="textDescription" />

    <v-dialog width="80%" v-model="dialogOrder" v-if="dialogOrder" transition="dialog-bottom-transition">
      <s-license v-if="part" style="overflow: hidden !important" @dblClick="dblClick($event)"></s-license>
      <s-component v-else style="overflow: hidden !important" @dblClick="dblClick($event)"></s-component>
    </v-dialog>
  </div>
</template>
<script>
import SLicense from "@/views/InformationTechnology/Inventory/License.vue";
import SComponent from "@/views/InformationTechnology/Inventory/Component.vue";
export default {
  components: { SLicense, SComponent },
  props: {
    value: null,
    readonly: { type: Boolean, default: false },
    label: { type: String, default: "Licencias" },
    part: { type: Boolean, default: true },
  },
  data() {
    return {
      article: null,
      textDescription: "",
      dialogOrder: false,
    };
  },
  methods: {
    dblClick(item) {
      this.article = item;
      this.$emit("input", item);
      this.textDescription = item.OrdDocumentNumber;
      this.dialogOrder = false;
    },
  },
  created() {
    this.article = this.value;
    if (this.article != null) {
      this.textDescription = this.article.OrdDocumentNumber;
    }
  },
  created() {},
  watch: {
    value() {
      this.textDescription = this.value.OrdDocumentNumber;
    },
  },
};
</script>

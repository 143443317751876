<template>
  <div>
    <s-text :readonly="readonly" label="Ordenes Compra" @click="dialogOrder = true" v-model="textDescription" />

    <v-dialog width="80%" v-model="dialogOrder" v-if="dialogOrder" transition="dialog-bottom-transition">
      <v-order-list style="overflow: hidden !important" @dblClick="dblClick($event)"></v-order-list>
    </v-dialog>
  </div>
</template>

<script>
import VOrderList from "@/views/General/Acquisition/Order/Detail.vue";
export default {
  components: { VOrderList },
  props: {
    value: null,
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      article: null,
      textDescription: "",
      dialogOrder: false,
    };
  },
  methods: {
    dblClick(item) {
      this.article = item;
      this.$emit("input", item);
      this.textDescription = item.OrdDocumentNumber;
      this.dialogOrder = false;
    },
  },
  created() {
    this.article = this.value;
    if (this.article != null) {
      this.textDescription = this.article.OrdDocumentNumber;
    }
  },
  created() {},
  watch: {
    value() {
      this.textDescription = this.value.OrdDocumentNumber;
    },
  },
};
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col lg="2" cols="6" class="s-col-form">
        <s-select-definition label="Tipo Adquisición" :def="1177" v-model="purchase.TypePurchase"></s-select-definition>
      </v-col>
      <v-col lg="4" cols="6" class="s-col-form">
        <s-supplier label="Proveedor" v-model="purchase.ProviderName" />
      </v-col>
      <v-col cols="6" lg="2" class="s-col-form">
        <s-purchase label="Orden Compra" v-model="purchase.PcsOrdID" @input="inputPurchase($event, purchase)" />
      </v-col>
      <v-col lg="2" cols="6" class="s-col-form">
        <s-text label="Factura" v-model="purchase.PcsInvoice" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="1" cols="6" class="s-col-form">
        <s-text label="Guía" v-model="purchase.PcsReferralGuide" />
      </v-col>
      <v-col lg="1" cols="6" class="s-col-form">
        <s-text label="Precio" decimal v-model="purchase.PcsPryce" />
      </v-col>

      <v-col lg="2" cols="6" class="s-col-form">
        <s-date label="Fecha adquisición" v-model="purchase.PcsInvoiceDate" />
      </v-col>
      <v-col lg="2" cols="6" class="s-col-form">
        <s-date clearable label="Fecha Devolucion" v-model="purchase.PcsDateEnd" />
      </v-col>
      <v-col lg="4" cols="12" class="s-col-form"><s-text label="Observacion" v-model="purchase.PcsObservation" /></v-col>
    </v-row>
  </v-container>
</template>
<script>
import SSupplier from "../../../components/Utils/Logistics/SSupplier.vue";
import SPurchase from "@/views/General/Acquisition/Order/Orders";
export default {
  components: { SSupplier, SPurchase },
  props: ["avisar"],
  data() {
    return {
      purchase: {},
      capture: {},
    };
  },
  created() {},
  methods: {
    save() {
      if (!this.validationForm(this.purchase)) {
        this.$emit("updateForm", this.purchase);
      }
    },

    inputPurchase(data, item) {
      console.log("data", data);
      console.log("item", item);
      this.purchase.PcsCardCode = data.PcsCardCode;
      this.purchase.PcsInvoice = data.PcsInvoice;
      this.purchase.ProviderName = data.ProviderName;
      this.purchase.PcsInvoiceDate = data.PcsInvoiceDate;
      this.purchase.PcsPryce = data.PcsPryce;
      this.purchase.SupName = data.ProviderName;
    },

    validationForm(item) {
      let isValid;
      let message;

      isValid = item.TypePurchase > 0;
      if (!isValid) message = "Seleccione tipo Adquisicion";
      else {
        isValid = item.PcsCardCode.length > 0;
        if (!isValid) message = "Seleccione Proveedor";
        else {
        }
      }
      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
  },
};
</script>

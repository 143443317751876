import Service from "../Service";
const resource = "genpurchase/";

export default {
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
};

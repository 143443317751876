<template>
  <div>
    <v-row>
      <v-col><s-crud searchInput @doubleClick="dobleClick" title="Ordenes de Compra" :config="config"> </s-crud></v-col>
    </v-row>
  </div>
</template>

<script>
import _sPurchase from "../../../../services/General/PurchaseService";
export default {
  components: {},
  props: {},

  data: () => ({
    config: {
      model: {
        DocEntry: "ID",
        PcsInvoiceDate: "date",
      },
      service: _sPurchase,
      headers: [
        { text: "N° Orden", value: "OrdDocumentNumber", sortable: false },
        { text: "Proveedor", value: "ProviderName", sortable: false },
        { text: "Factura", value: "PcsInvoice", sortable: false },
        { text: "F.Factura", value: "PcsInvoiceDate", sortable: false },
      ],
    },
  }),
  methods: {
    dobleClick(item) {
      this.$emit("dblClick", item);
    },
  },
  created() {},
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <s-crud
          edit
          :filter="filter"
          searchInput
          :config="config"
          add
          remove
          title="Licencias"
          @save="save($event)"
        >
          <template scope="props">
            <v-container>
              <div>
                <v-row justify="center">
                  <v-col cols="3" lg="3" class="s-col-form">
                    <s-select-definition
                      :def="1178"
                      add
                      label="Licencia"
                      v-model="props.item.TypeLicense"
                    />
                  </v-col>
                  <v-col cols="3" lg="3" class="s-col-form">
                    <s-select-definition
                      :def="1167"
                      add
                      label="Tipo Licencia"
                      v-model="props.item.TypeLicensePlan"
                    />
                  </v-col>
                  <v-col cols="3" lg="3" class="s-col-form">
                    <s-date
                      label="Fecha Inicio"
                      v-model="props.item.LcsDateBegin"
                    />
                  </v-col>
                  <v-col cols="3" lg="3" class="s-col-form">
                    <s-date label="Fecha Fin" v-model="props.item.LcsDateEnd" />
                  </v-col>
                  
                </v-row>

                <v-row justify="center">
                  <v-col cols="3" lg="3" class="s-col-form">
                    <s-select-definition
                      :def="1179"
                      label="Estado"
                      v-model="props.item.LcsStatus"
                    />
                  </v-col>
                  <v-col lg="3" cols="3" class="s-col-form">
                    <s-text
                      label="Descripción"
                      v-model="props.item.LcsObservation"
                    />
                  </v-col>
                  <v-col
                    :lg="props.item.LcsStandar ? 2 : 4"
                    :cols="props.item.LcsStandar ? 2 : 4"
                    class="s-col-form"
                  >
                    <s-switch
                      label="Standart"
                      v-model="props.item.LcsStandar"
                    />
                  </v-col>
                  <v-col
                    :lg="props.item.LcsStandar ? 2 : 4"
                    :cols="props.item.LcsStandar ? 2 : 4"
                    class="s-col-form"
                    v-if="props.item.LcsStandar"
                  >
                    <s-text label="Cantidad" v-model="props.item.LcsQuantity" />
                  </v-col>
                  <v-col lg="2" cols="2" class="s-col-form">
                    <s-switch
                      label="Datos de Compra"
                      v-model="props.item.Status"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="props.item.Status">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row justify="center" v-if="props.item.Status">
                  <v-label>Precio / Documentos</v-label>
                  <s-purchase
                    @updateForm="saveVerdadero($event)"
                    ref="childAcquisition"
                    :avisar="onResultados"
                  ></s-purchase>
                </v-row>
              </div>
            </v-container>
          </template>

          <template v-slot:LcsStatus="{ row }">
            <v-chip x-small :color="row.LcsStatusColor">
              {{  row.LcsStatusName }}
            </v-chip>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _service from "@/services/InformationTechnology/LicenseService";
import SPurchase from "@/views/General/Acquisition/Acquisition";
export default {
  components: { SPurchase },

  data() {
    return {
      filter: {},
      config: {
        model: {
          LcsID: "ID",
          LcsDateBegin: "date",
          LcsDateEnd: "date",
          LcsStatus: 0,
        },
        headers: [
          { text: "ID", value: "LcsID" },
          { text: "Licencia", value: "TypeLicenseName" },
          { text: "Tipo Licencia", value: "TypeLicensePlanName" },
          { text: "Standart", value: "LcsStandarName" },
          { text: "Cantidad", value: "LcsQuantity" },
          { text: "F. Licencia", value: "LcsDateBegin" },
          { text: "F. Fin", value: "LcsDateEnd" },
          { text: "Estado", value: "LcsStatus" },
          { text: "Observación", value: "LcsObservation" },
          { text: "Acción", value: "LcsAction" },
        ],
        service: _service,
      },
      items: [],
      license: {},
    };
  },
  created() {},
  methods: {
    getPurchase(item) {
      return true;
    },

    saveVerdadero(purchase) {
      _service
        .save(this.license, purchase, this.$fun.getUserID())
        .then((response) => {
          if (response.status == 200) {
            if (this.license.LcsID == 0) {
              this.$fun.alert("Agregado Correctamente", "success");
            } else {
              this.$fun.alert("Actualizado Correctamente", "success");
            }
          }
        });

      item.save();
      console.log(purchase);
    },

    save(item) {
      item.save();
    },
    onResultados(datos) {
      alert("retorne el valor", datos);
    },
  },
};
</script>

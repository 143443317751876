<template>
  <v-container>
    <v-row>
      <v-col>
        <s-crud edit :filter="filter" :config="config" add remove title="Inventario de Equipos" @save="save($event)">
          <template scope="props">
            <v-container>
              <v-row justify="center">
                <v-col lg="2" cols="12" class="s-col-form">
                  <s-select-definition :def="1163" add label="Tipo de Equipo" v-model="props.item.ArcID" />
                </v-col>
                <v-col lg="2" cols="6" class="s-col-form">
                  <s-select-definition :def="1164" add label="Marca" v-model="props.item.TypeBrand" />
                </v-col>
                <v-col lg="2" cols="6" class="s-col-form">
                  <s-text label="Modelo" v-model="props.item.ExtModel" />
                </v-col>
                <v-col lg="2" cols="12" class="s-col-form">
                  <s-text label="Serie" v-model="props.item.ExtSerie" />
                </v-col>
                <v-col cols="6" lg="2" class="s-col-form">
                  <s-select-definition :def="1166" label="Color" v-model="props.item.TypeColor" />
                </v-col>
                <v-col cols="6" lg="2" class="s-col-form">
                  <s-select-definition :def="1179" label="Estado de Equipo" v-model="props.item.ExtStatus" />
                </v-col>
              </v-row>
              <div>
                <v-col style="padding-top: 18px">
                  <v-row>
                    <v-col lg="5">
                      <s-toolbar
                        color=""
                        label="Item"
                        add
                        edit
                        remove
                        @add="add()"
                        @edit="edit()"
                        @removed="removeItem()"
                        @save="add()"
                        @clear="clearForm()"
                        clear
                      />
                      <v-divider></v-divider>
                      <v-card rounded="" class="elevation-0">
                        <v-col style="padding-bottom: 24px">
                          <v-row justify="center">
                            <v-col lg="5" cols="12" class="s-col-form">
                              <s-select-definition label="Tipo" :def="1324" v-model="props.item.TypeObject"></s-select-definition>
                            </v-col>
                            <v-col class="s-col-form" lg="7" cols="12">
                              <s-object
                                :part="props.item.TypeObject == 1 ? true : false"
                                :label="props.item.TypeObject == 1 ? 'Licencias' : 'Componentes'"
                                v-model="props.item.CmpID"
                              ></s-object>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col lg="12" class="s-col-form">
                              <s-text label="Descripcion" />
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col class="s-col-form" lg="6" cols="12">
                              <s-text label="Cantidad" decimal ref="DerQuantity" />
                            </v-col>
                            <v-col class="s-col-form" lg="6" cols="12">
                              <s-text label="Stock" disabled ref="" v-if="article" />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-col>

                    <v-col lg="7">
                      <s-toolbar label="Detalle"></s-toolbar>
                      <v-card rounded="" class="elevation-0">
                        <v-col style="padding: 0px">
                          <v-data-table mobile-breakpoint="0" dense class="elevation-0"> </v-data-table>
                        </v-col>

                        <v-col style="">
                          <v-row>
                            <v-col style="padding-top: 0px">
                              <s-textarea label="Comentario General"></s-textarea>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-row
                  ><v-col lg="6"> <v-label>Precio / Documentos</v-label></v-col>
                  <v-col lg="4" cols="6" class="s-col-form">
                    <s-switch label="Datos de Compra" v-model="props.item.Status" />
                  </v-col>
                </v-row>
                <v-row v-if="props.item.Status">
                  <s-purchase @updateForm="saveVerdadero($event)" ref="childAcquisition" :avisar="onResultados"></s-purchase>
                </v-row>
              </div>
            </v-container>
          </template>
          <template v-slot:ExtStatus="{ row }">
            <v-chip x-small :color="row.ExtStatus == 1 ? 'success' : 'error'">{{ row.ExtStatus == 1 ? "Activo" : "Baja" }}</v-chip>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _service from "@/services/InformationTechnology/InventoryService";
import _sPhysicalItem from "@/services/InformationTechnology/PhysicalItemService";
import SObject from "@/views/InformationTechnology/Inventory/View.vue";
import SPurchase from "@/views/General/Acquisition/Acquisition";
export default {
  components: { SObject, SPurchase },
  data() {
    return {
      filter: {},
      config: {
        model: {
          ExtID: "ID",
          TinDateDelivery: "date",
          TinDateReturn: "date",
          ExtStatus: "",
        },
        headers: [
          { text: "Est", value: "ExtStatus" },
          { text: "Tipo  Equipo", value: "TypeEquipmentName" },
          { text: "Marca", value: "TypeBrandName" },
          { text: "Modelo", value: "ExtModel" },
          { text: "Serie", value: "ExtSerie" },
          { text: "Color", value: "TypeColorName" },
          { text: "Tipo  Adquisición", value: "TypeAcquisitionName" },
          { text: "IP", value: "TinIP" },
          { text: "Tipo de Porcesador", value: "TypeProcessorName" },
          { text: "Velocidad Procesador", value: "TinSppedyProcessor" },
          { text: "HDD", value: "TinHDD" },
          { text: "Memoria", value: "TinMemoryMB" },
          { text: "Sistema Operativo", value: "TypeOperatingSystemName" },
          { text: "Serie Sistema", value: "TinSerieOperationSystem", width: 250 },
          { text: "Licencia Sistema", value: "TinStatusLicenseSystem" },
          { text: "Office", value: "TypeOfficeName", width: 250 },
          { text: "Licencia Office", value: "TinStatusLicenseOffice" },
          { text: "Código Office", value: "TinCodeActivationOffice", width: 250 },
          { text: "Antivirus", value: "TypeAntivirusName" },
          { text: "Licencia Antivirus", value: "TypeAntivirusLicenseName" },
          { text: "Proveedor", value: "SupName", width: 350 },
          { text: "Ord Compra", value: "TinOrderPurchase" },
          { text: "Factura", value: "TinInvoicePurchase" },
          { text: "Guía", value: "TinReferralGuide" },
          { text: "Fecha", value: "TinDateDelivery" },
          { text: "Fecha devolución", value: "TinDateReturn" },
          { text: "Precio", value: "TinPrice" },
          { text: "Observación", value: "TinObservation" },
        ],
        service: _service,
      },
      items: [],
    };
  },
  created() {},
  methods: {
    save(item) {
      if (!item.TinSppedyProcessor) item.TinSppedyProcessor = 0;
      if (!item.TinHDD) item.TinHDD = 0;
      if (!item.TinPrice) item.TinPrice = 0;
      if (!item.TinMemoryMB) item.TinMemoryMB = 0;
      if (this.validationForm());
      item.save();
    },
    validationForm() {
      return true;
    },
  },
};
</script>
